@import './base';
@import './mixins';
@import './variables';

body{
    // scroll-bar
    /* width */
    &::-webkit-scrollbar {
        width: 8px;
    }    
    // scroll buttons
    &::-webkit-scrollbar-button{
        display: none;
    }    
    /* Track */
    &::-webkit-scrollbar-track-piece{
        background: lightgray;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $primary-color-light;
        border-radius: 5px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $primary-color-dark;
    }

}