.table-small {
  & > table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
    margin-bottom: 0 !important;
  }
  td,
  th {
    font-size: 1.6rem;
    width: 50%;
    border-top: 1px solid #ddd;
    text-align: left;
    padding: 5px;
    border-right: 1px solid #ddd;
  }
  // tr:nth-child(2) {
  //   display: none;
  // }
  td,
  th:last-child() {
    border-right: none;
  }
  tr:nth-child(odd) {
    background-color: #fff;
  }
  .header {
    background-color: $primary-color-light;
    color: $color-white;
  }
}
