@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Viga&display=swap");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:200,200i,300,300i,400,400i,600,600i,700,700i,800,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

// RESPONSIVE BREAKPOINTS
$bp-largest: 75em; // 1200px
$bp-large: 62.5em; // 1000px
$bp-medium: 50em; // 800px;
$bp-small: 37.5em; // 600px;

// COLOR VARIABLES
$color-primary: #02c39a;
$color-primary-dark: #00a896;

$color-secondary: #028090;

$color-grey-light-1: #22a565;
$color-grey-light-2: #22a565;

$color-grey-dark-1: #22a565;
$color-grey-dark-2: #22a565;

$text-color: #f9effb;

// FONT VARIABLES
$font-primary: "Nunito", sans-serif;
$font-display: "Josefin Sans", sans-serif;

$open-sans: "Open Sans", sans-serif;
$roboto-font: "Roboto", sans-serif;
$popins-font: "Poppins", sans-serif;
$montserrat-font: "Montserrat", sans-serif;
$viga-font: "Viga", sans-serif;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px

  @media only screen and (max-width: $bp-largest) {
    font-size: 50%;
  }
}

body {
  font-family: $montserrat-font;
  color: $text-color;
  font-weight: 300;
  line-height: 1.6;
}
