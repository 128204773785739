.student-container {
  width: 100%;
  background-color: $primary-color-light;
  border-radius: 10px;
  color: $color-white;
  .profile-pic {
    width: 60%;
    border-radius: 50%;
    background-size: cover;
    border: 5px solid $color-white;
    box-shadow: 0px 3px 6px #00000029;
    &::after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    margin: 0 auto;
  }
  .student-details {
    p {
      max-width: 300px;
      margin: 0 auto;
      overflow: hidden;
      span {
        display: inline-block;
        &:last-child {
          float: right;
          width: 40%;
        }
      }
    }
  }
  .student-class-details {
    p {
      max-width: 300px;
      margin: 0 auto;
      overflow: hidden;
      span {
        display: inline-block;
        &:last-child {
          float: right;
          width: 60%;
        }
      }
    }
  }
  .student-institute-details {
    p {
      max-width: 300px;
      margin: 0 auto;
      overflow: hidden;
    }
  }
  & > div {
    min-height: 240px;
    & > div {
      align-items: center;
      border-right: 1px solid #988e8e;
      &:last-child {
        border: none;
      }
      p {
        font-size: 2rem;
      }
    }
  }
}
@media (max-width: 992px) {
  .student-container {
    padding-bottom: 20px;
    & > div {
      min-height: 240px;
      & > div {
        align-items: center;
        &:first-child {
          border-right: none;
          padding: 20px 0;
        }
        p {
          font-size: 2rem;
        }
      }
    }
    :first-child .student-details {
      position: relative;
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 95px;
        height: 1px;
        width: 80%;
        margin: 0 auto;
        background-color: #988e8e;
      }
    }
  }
}
@media (max-width: 575px) {
  .student-container {
    & > div {
      min-height: 240px;
      & > div {
        align-items: center;
        & > span {
          border-bottom: 1px solid #988e8e;
          display: block;
          height: 2px;
          width: 85%;
          margin: 10px auto;
        }
        p {
          font-size: 2rem;
        }
      }
    }
    .student-details {
      padding-left: 5px !important;
      & > span {
        border-bottom: 1px solid #988e8e;
        display: block;
        height: 2px;
        width: 80%;
        margin: 10px auto;
      }
    }
    :first-child .student-details {
      position: relative;
      &::after {
        display: none;
      }
    }
    .profile-pic {
      width: 40%;
      margin-bottom: 20px;
    }
  }
}
