body {
  padding: 0;
  margin: 0;
}
.header-container {
  margin-left: auto;
  margin-right: 1rem;
  height: 2.5rem;
  & > svg {
    height: 2.5rem;
    width: 2.5rem !important;
    color: $primary-color-dark;
    margin-right: 3rem;
  }
  & > img {
    height: 4.2rem;
    width: 4.2rem;
    border-radius: 50%;
  }
}
