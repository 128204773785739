.custom-table{
    font-size: 1.5rem;

    .row{
        height: 100%;
        border-top: 1px solid #DFE0FF;
        &:last-child{
            border-bottom: 1px solid #DFE0FF;
        }
    }

    .table-title{
        background: $primary-color-dark;
        color: $color-white;
        font-size: 1.9rem;
        font-size: bold;
        padding: 1rem 1.5rem;
        border: 1px solid #DFE0FF;
    }
    .data-title, .data-value{
        border-left: 1px solid #DFE0FF;
        padding: 1rem 1.5rem;
    }
    .data-title{
        background: $color-white;
    }
}