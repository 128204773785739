.side-bar{
    background: $primary-color-light;
    color: $color-white;
    font-size: 2rem;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;

    ul{
        a{
            text-decoration: none;
            color: $color-white;
            display: block;
        }
        a.nav-active{
            font-weight: 600;
            background-color: $color-white;
            color: $primary-color-light;
            svg{
                color: $primary-color-light;
            }
        }
        span{
            font-weight: lighter;
            font-size: 2rem;
        }

        svg{
            color: $color-white;
        }

        .sub-menu.active, .sub-menu.active a{
            background: $color-white;
            color: $primary-color-light;
            svg{
                color: $primary-color-light;
            }
        }

        & a > div, .sub-menu > div {
            margin: 1.5rem 0;

            & > div:nth-child(1){
                min-width: 4rem;
            }

            & > div:nth-child(3){
                min-width: max-content;
            }

        }

        .sub-menu a {
            .sub-menu-item-icon {

                &::before{
                    content: "\f111";
                    font-family: "FontAwesomeRegular";
                    color: $primary-color-light;
                }
            }
             & > div{
                border-top: 1px solid lightgrey;
                align-items: center;
                display: flex;
                padding-top: 0;
                padding-bottom: 0;
                margin: 5px 0;
                
                div:first-of-type{
                    font-size: 1rem;
                    justify-content: center;
                }
            }
            &.active{
                font-weight: bold;

                .sub-menu-item-icon::before{
                    content: "\f111";
                    font-family: "FontAwesome";
                }
            }
        }

    }
    
    h1 a{
        color: $color-white;
        &:hover{
            text-decoration: none;
        }
    }

    .logo-container{
        height: 64px;
    }
}

.dashboard{
    
    &, header{
        background: $secondary-color-light;
        box-shadow: none;
    }

}