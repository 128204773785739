@font-face {
  font-family: "FontAwesome";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  // src: url("../webfonts/fa-brands-400.eot");
  src: url("../../asset/fonts/fa-solid-900.eot");
  src: url("../../asset/fonts/fa-solid-900.eot?#iefix")
      format("embedded-opentype"),
    url("../../asset/fonts/fa-solid-900.woff2") format("woff2"),
    url("../../asset/fonts/fa-solid-900.woff") format("woff"),
    url("../../asset/fonts/fa-solid-900.ttf") format("truetype"),
    url("../../asset/fonts/fa-solid-900.svg#fontawesome") format("svg");
}

@font-face {
  font-family: "FontAwesomeRegular";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  // src: url("../webfonts/fa-brands-400.eot");
  src: url("../../asset/fonts/fa-regular-400.eot");
  src: url("../../asset/fonts/fa-regular-400.eot?#iefix")
      format("embedded-opentype"),
    url("../../asset/fonts/fa-regular-400.woff2") format("woff2"),
    url("../../asset/fonts/fa-regular-400.woff") format("woff"),
    url("../../asset/fonts/fa-regular-400.ttf") format("truetype"),
    url("../../asset/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

.subjectList-datatable {
  .datatable-responsive-demo
    .p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td
    .p-column-title {
    display: none;
  }
  .header {
    background-color: $primary-color-dark;
    margin: 0 auto;
    width: 100%;
    color: #fff;
    font-size: 1.8rem;
    font-weight: bold;
  }
  .p-inputtext {
    width: 100%;
    font-size: 1.6rem;
  }
  .p-datatable .p-datatable-thead > tr > th {
    border: none;
    background-color: $primary-color-light;
    color: $color-white;
    font-size: 1.6rem;
    font-weight: semi-bold;
    border-right: 1px solid #c1c1c1;
    &:last-child {
      border-right: none;
    }
  }
  .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: $color-white;
    &:hover {
      color: #fff;
    }
  }
  .p-datatable .p-sortable-column {
    &:focus {
      box-shadow: none;
    }
  }
  .p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: $primary-color-light;
    color: $color-white;
  }

  .p-datatable .p-sortable-column:hover .p-sortable-column-icon {
    color: white;
  }

  .p-sortable-column-icon {
    display: inline-block;
    font-size: 1.2rem;
    text-align: right;

    &::before {
      content: "\f0dc";
      font-family: "FontAwesome";
    }
  }
  .p-datatable .p-sortable-column.p-highlight:hover {
    background: $primary-color-light;
    color: $color-white;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    font-size: 1.4rem;
    border-right: 1px solid #c1c1c1;
    &:last-child {
      border-right: none;
    }
  }
  .p-paginator {
    justify-content: flex-end;
  }
  .p-datatable .p-datatable-tbody > tr:nth-child(even) {
    background-color: #f2f4ff;
  }
  @media screen and (max-width: 40em) {
    .datatable-responsive-demo
      .p-datatable.p-datatable-responsive-demo
      .p-datatable-thead
      > tr
      > th,
    .datatable-responsive-demo
      .p-datatable.p-datatable-responsive-demo
      .p-datatable-tfoot
      > tr
      > td {
      display: none !important;
    }

    .datatable-responsive-demo
      .p-datatable.p-datatable-responsive-demo
      .p-datatable-tbody
      > tr
      > td {
      text-align: left;
      display: block;
      width: 100%;
      float: left;
      clear: left;
      border: 0 none;
    }

    .datatable-responsive-demo
      .p-datatable.p-datatable-responsive-demo
      .p-datatable-tbody
      > tr
      > td
      .p-column-title {
      padding: 0.4rem;
      min-width: 30%;
      display: inline-block;
      margin: -0.4em 1em -0.4em -0.4rem;
      font-weight: bold;
    }

    .datatable-responsive-demo
      .p-datatable.p-datatable-responsive-demo
      .p-datatable-tbody
      > tr
      > td:last-child {
      border-bottom: 1px solid var(--surface-d);
    }
  }
}
