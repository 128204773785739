.required-sign{
    color: $color-sweet-red;
}

.input-label{
    color: $primary-color-light;
    font-size: 1.4rem;
    @media (max-width: 767px){
        margin-top: .7rem;
    }
}

.css-1okebmr-indicatorSeparator, .css-109onse-indicatorSeparator{
    display: none;
}

.css-yk16xz-control{
    font-size: 1.4rem;
}

.search-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    background: $primary-color-light;
    padding: 0 4rem;
    border: none;
    border-radius: 4px;
    padding: 10px 0;
    width: 100%;
    max-width: 200px;
    font-size: 1.4rem;

    @media(max-width: 767px){
        margin-top: 1rem;
    }
}

.css-2613qy-menu{
    font-size: 1.4rem;
    width: 100px;
}