
// Responsive Variables

// min : 1200px, max 1499
$desktop-device: "only screen and (min-width: 75em) and (max-width: 93.6875em)";

// min : 992px, max 1199
$laptop-device: "only screen and (min-width: 62em) and (max-width: 74.9375em)";

// min : 768px, max 991px
$tablet-device: "only screen and (min-width: 48em) and (max-width: 61.9375em)";

// max 767px
$large-mobile: "only screen and (max-width: 47.9375em)";

// max 575px
$small-mobile: "only screen and (max-width: 35.9375em)";

// max 479px
$extra-small-mobile: "only screen and (max-width: 29.9375em)";

$primary-color-light: #565AEC;
$primary-color-dark: #2125BA;
$secondary-color-dark: #DDE2FF;
$secondary-color-light: #F2F4FF;
$color-black: #000000;
$color-white: #ffffff;
$color-sweet-red: #EB5555;

// $color-primary-overlay: #22A565;
// $color-light-1: #22A565;
// $color-light-2: #22A565;
// $color-light-back-1: #22A565;
// $color-light-back-2: #22A565;
// $color-dark-1: #22A565;
// $color-golden:#22A565;