.class-routine-header{
    background: $primary-color-dark;
    padding: 1rem 2rem;
    color: $color-white;
    font-size: 2.4rem;
    border: 2px solid #DDE2FF;
    h2{
        font-weight: bold;
    }
}

.class-routine-table{
    overflow-x: auto;
    max-width: calc(100vw - 300px);
    padding: 0 0 10px 0;
    table{
        font-size: 1.8rem;
        th, td{            
            border: 2px solid #DDE2FF;
            vertical-align: middle;
        }
    }
    thead{
        text-align: center;
        tr{
            font-size: 1.5rem;
            &:first-child{
                font-size: 2.2rem;
                th:first-child{
                    font-size: 2.1rem;
                }
            }            
        }
    }
    .table-colored{
        background: $primary-color-light;
        color: $color-white;
    }
    tbody{
        font-weight: normal;
        tr:nth-child(2n){
            background: $color-white;
        }
        tr td:first-child{
            font-weight: 500;
        }
    }
    @media (max-width: 1511px){
        table{
            font-size: 1.4rem;

            thead tr{
                    font-size: 1.2rem;
                    &:first-child{
                        font-size: 1.7rem;
                        th:first-child{
                            font-size: 1.7rem;
                        }
                    }
                }
        }
    }
    @media (max-width: 959px){
        max-width: calc(100vw - 50px);
    }


// scroll-bar
    /* width */
    &::-webkit-scrollbar {
        height: 10px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent; 
    }

    &::-webkit-scrollbar-button{
        color: red;
        // background: #000;
    }

    &::-webkit-scrollbar-track-piece{
        color: red;
        background: lightgray;
        border-radius: 5px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $primary-color-light;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $primary-color-dark;
    }
}

.class-routine-table-sm{
    table{
        font-size: 1.7rem;
    }
    thead {
        background: $primary-color-light;
        color: $color-white;

        tr th{
            font-size: 2rem!important;
        }
    }
    td, th{
        text-align: center;
    }
}