.footer-container {
  color: $primary-color-dark;
  //   margin-left: auto;
  //   margin-right: 20px;
  // margin: 20px 30px 20px auto;
  font-size: 1.4rem;
  text-align: end;
  position: absolute;
  bottom: 10px;
  right: 25px;
  height: 2rem;
  & > p > span {
    font-weight: 800;
  }
}
