.rectangle{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $primary-color-light;
    background: $color-white;
    padding: 1.5rem;
    border: 1px solid #DFE0FF;
    border-radius: 5px;
    user-select: none;
    cursor: pointer;
    transition: all 0.3s;

    &:hover{
        color: $color-white;
        background: $primary-color-light;

        .circle{
            color: $primary-color-light;
            background: $color-white;
        }
    }

    .circle{
        width: 30%;
        border-radius: 50%;
        color: $color-white;
        display: flex;
        background: $primary-color-light;        
        align-items: center;
        justify-content: center;
        transition: all 0.3s;

        &::after{
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        h2{
            font-weight: bold;
            margin-bottom: 0;
            font-size: 2vw;
        }
    }

    p{
        margin-top: 1rem;
        margin-bottom: 0;
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: bold;
    }

    @media (max-width: 991px){
        margin-top: 1rem;

        .circle h2{
            font-size: 3.5vw;
        }
    }
}