.auth-page {
  background-color: $primary-color-light;
  min-height: 100vh;
  display: grid;
  padding: 8vh 0;
  font-family: Roboto;
  overflow: hidden;
  .container-fluid {
    height: 100%;
    padding: 0 15rem;
    .row {
      overflow: hidden;
      border-radius: 10px;
      background-color: $color-white;
      height: 100%;
      padding: 2rem 0;
      img.img-fluid {
        max-height: 42rem;
      }
      .content {
        margin-top: 3rem;
        max-width: 47rem;
        h1 {
          color: $primary-color-light;
          letter-spacing: 0.5rem;
          font-size: 4rem;
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom: 2rem;
          user-select: none;
        }
        p {
          color: $color-black;
          font-size: 2.1rem;
          line-height: 1.1em;
          letter-spacing: 0.1rem;
          margin-bottom: 2rem;
          user-select: none;
        }
        input {
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          &[type="number"] {
            -moz-appearance: textfield;
          }
        }
        form {
          & > div,
          & > button {
            margin-top: 1.8rem;
            & > p {
              font-size: 1.6rem;
              margin-bottom: 0;
            }
          }
          & > div > div {
            font-size: 1.8rem;
            padding: 0;
          }
          & span {
            font-size: 1.6rem;
          }
          div > label {
            font-size: 1.8rem;
            margin-left: 0.5rem;
            span {
              color: $color-sweet-red;
            }
          }
          div > label > span {
            font-size: 1.6rem;
            span > svg {
              font-size: 2.5rem;
              color: $primary-color-light;
            }
          }
          div > p {
            font-size: 1.3rem;
          }
          div > a,
          div > div > p {
            font-size: 1.6rem;
            margin-left: 1.8rem;
            a {
              font-weight: bold;
            }
          }
          div > div > div > button {
            border-radius: 5px;
            width: 60px;
            height: 5.7rem;
            color: white;
            background-color: $primary-color-light;
            & > span > svg {
              font-size: 2.5rem;
            }
            &:hover {
              background-color: $primary-color-dark !important;
            }
          }
          div > div {
          }
          .row label span {
            color: $color-black;
          }
        }
        form > button,
        .verification-btn {
          padding: 1.7rem 0;
          font-size: 1.3rem;
          letter-spacing: 0.2rem;
          background: linear-gradient(
            to right,
            $primary-color-dark,
            $primary-color-light
          );
          transition: 0.3s all;
          &:hover {
            background: linear-gradient(
              to right,
              $primary-color-light,
              $primary-color-dark
            );
          }
          &[disabled=""] {
            color: $color-black;
            background: $primary-color-light;
          }
        }
        .otp {
          margin-top: 4rem;
          h3 {
            letter-spacing: 0.2rem;
            user-select: none;
          }
          h3,
          p span {
            text-transform: uppercase;
            color: $primary-color-light;
          }
          p {
            font-size: 1.6em;
            margin-top: 1.2rem;
            span {
              font-weight: bold;
            }
            span:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }
          .otp-input {
            margin-top: 10px;
            max-width: 378px;
            & > div {
              display: flex;
              justify-content: space-between;
              width: 100%;
            }
            input {
              height: 5rem !important;
              width: 5rem !important;
              line-height: 5rem;
              font-size: 2rem;
              cursor: context-menu;
              text-align: center;
              border: 2px solid $primary-color-light;
            }
          }
        }
      }
    }
  }
}

.MuiMenuItem-root {
  font-size: 1.8rem !important;
}

@media (max-width: 1200px) {
  .auth-page {
    padding: 3rem 0;
    .container-fluid {
      padding: 0 10rem;
    }
  }
}

@media (max-width: 768px) {
  .auth-page {
    .container-fluid {
      padding: 0 5rem;
    }
    .content {
      margin: 3rem auto;
      max-width: max-content;
      .otp-input {
        max-width: 300px !important;
      }
    }
  }
}
